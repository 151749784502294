<template>
  <div class="info-card-container" v-if="stock.currentPrice">
    <b-card class="info-card">
      <b-row>
        <b-col>
          <h3>{{ stock.name }}</h3>
        </b-col>
        <b-col>
          <p>
            Price Difference (24h):
            <span :style="{ color: col24h }">{{ diff24h }} <i :class="['bi', arrow24h]"></i></span>
          </p>
        </b-col>
        <b-col>
          <p>
            Price Difference (1d):
            <span :style="{ color: col1w }">{{ diff1w }} <i :class="['bi', arrow1w]"></i></span>
          </p>
        </b-col>
        <b-col>
          <p>
            Price Difference (1m):
            <span :style="{ color: col1m }">{{ diff1m }} <i :class="['bi', arrow1m]"></i></span>
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {} from "../client/index";
import moment from "moment";
require("moment/locale/de");
// Moment auf Deutsch einstellen
moment.locale("de");

export default {
  name: "priceBarComponent",
  components: {},
  props: {
    title: String,
    data: Array,
  },
  data() {
    return {
      stock: {
        name: "USDT/EUR",
        currentPrice: 100,
        lo24h: 90,
        hi24h: 105,
        close24h: 105,
        lo1w: 70,
        hi1w: 105,
        close1w: 85,
        lo1m: 70,
        hi1m: 110,
        close1m: 100,
      },
      col24h: null,
      col1w: null,
      col1m: null,
      diff24h: null,
      diff1w: null,
      diff1m: null,
      arrow24h: null,
      arrow1w: null,
      arrow1m: null,
    };
  },
  created() {
    this.calculatePriceDifference();
  },
  mounted() {
    // this.balanceInEuro = (parseFloat(this.balance) * parseFloat(this.exchangeRate)).toFixed(3);
    // this.balanceInEuro = this.balance;
    // console.log(this.balance);
  },
  methods: {
    convertUnix(unixTimestamp) {
      // return moment(unixTimestamp).format("DD.MM.YYYY - HH:mm:ss");
      let startDate = moment(unixTimestamp).format("YYYY-MM-DD");
      let endDate = moment().format("YYYY-MM-DD");
      return moment(endDate).diff(moment(startDate), "days") + 1;
    },
    calculatePriceDifference() {
      this.diff24h = this.stock.currentPrice - this.stock.close24h;
      switch (true) {
        case this.diff24h > 0:
          this.col24h = "#60bc8b";
          this.arrow24h = "bi-arrow-up-right";
          break;
        case this.diff24h < 0:
          this.col24h = "red";
          this.arrow24h = "bi-arrow-down-right";
          break;
        default:
          this.col24h = "gray";
          this.arrow24h = "bi-arrow-right";
      }

      this.diff1w = this.stock.currentPrice - this.stock.close1w;
      switch (true) {
        case this.diff1w > 0:
          this.col1w = "#60bc8b";
          this.arrow1w = "bi-arrow-up-right";
          break;
        case this.diff1w < 0:
          this.col1w = "red";
          this.arrow1w = "bi-arrow-down-right";
          break;
        default:
          this.col1w = "gray";
          this.arrow1w = "bi-arrow-right";
      }

      this.diff1m = this.stock.currentPrice - this.stock.close1m;
      switch (true) {
        case this.diff1m > 0:
          this.col1m = "#60bc8b";
          this.arrow1m = "bi-arrow-up-right";
          break;
        case this.diff1m < 0:
          this.col1m = "red";
          this.arrow1m = "bi-arrow-down-right";
          break;
        default:
          this.col1m = "gray";
          this.arrow1m = "bi-arrow-right";
      }
      console.log(this._data);

      // this.col24h = this.stock[0].currentPrice > this.stock[0].close24h ? "green" : "red";
      // this.priceDifference24h = this.stock[0].hi24h - this.stock[0].lo24h > 0 ? this.stock[0].hi24h : 0;
      // this.col1w = this.stock[0].currentPrice > this.stock[0].close1w ? "green" : "red";
      // this.priceDifference1w = this.stock[0].hi24h - this.stock[0].lo24h > 0 ? this.stock[0].hi24h : 0;
      // this.col1m = this.stock[0].currentPrice > this.stock[0].close1m ? "green" : "red";
      // this.priceDifference1m = this.stock[0].hi24h - this.stock[0].lo24h > 0 ? this.stock[0].hi24h : 0;
    },
  },
};
</script>

<style scoped>
.info-card-container {
  padding: 0px 0px 5px 0px;
}

.info-card {
  /*background: linear-gradient(45deg, #64d4d7, #3b6df6);*/
  background-color: #141319;
  box-shadow: 0 0 15px rgba(173, 173, 173, 0.2);
  color: white;
  border-radius: 0;
}

.info-card .row {
  text-align: left;
}

.mini-text {
  color: #8d8d8d;
  font-size: 10px;
}

.card-body {
  font-weight: 800;
}
</style>
