<template>
  <div class="info-card-container">
    <b-card class="info-card">
      <b-row>
        <b-col cols="6" class="info-column">
          <div class="info-description"><b>Balance:</b></div>
          <div class="info-value">{{ balance }} USDT</div>
          <div class="info-value mini-text">{{ balanceInEuro }} EUR</div>
        </b-col>
        <b-col cols="6" class="info-column">
          <div class="info-description"><b>Kurs:</b></div>
          <div class="info-value">{{ exchangeRate }} USDT/EUR</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="info-column">
          <div class="info-description"><b>Verdiente Zinsen:</b></div>
          <div class="info-value">{{ parseFloat(earnedInterest).toFixed(4) }} USDT</div>
          <div class="info-value mini-text">{{ earnedInterestInEuro }} EUR</div>
        </b-col>
        <b-col cols="6" class="info-column">
          <div class="info-description">
            <b>{{ averageSymbol }} Zinssatz:</b>
          </div>
          <div class="info-value">{{ parseFloat(averageInterestRate).toFixed(2) }}% APR</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="info-column">
          <div class="info-description"><b>Zeitraum:</b></div>
          <div class="info-value">{{ daysSinceInvestment }} Tage</div>
        </b-col>
        <b-col cols="6" class="info-column">
          <div class="info-description">
            <b>{{ averageSymbol }} Zinssatz:</b>
          </div>
          <div class="info-value">{{ parseFloat(averageInterestRate).toFixed(2) }}%</div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { getLends, getSumInterest, getInitialData, getAvgInterestRate } from "../client/index";
import moment from "moment";
require("moment/locale/de");
// Moment auf Deutsch einstellen
moment.locale("de");

export default {
  name: "infoBarComponent",
  components: {},
  props: {
    title: String,
    data: Array,
    exchangeRate: String,
  },
  data() {
    return {
      averageSymbol: "Ø",
      balance: null,
      balanceInEuro: null,
      earnedInterest: null,
      earnedInterestInEuro: null,
      daysSinceInvestment: null,
      averageInterestRate: null,
      from: "",
      to: "",
      currency: "USDT",
    };
  },
  created() {
    getLends((data, error) => {
      if (error) {
        this.errored = true;
        this.error = error;
        console.log("comp errored!");
      }
      if (data) {
        // console.log(data);
        this.balance = parseFloat(data.find((item) => item.currency === "USDT").amount).toFixed(4);
        // console.log(this.exchangeRate);
        this.balanceInEuro = (parseFloat(this.balance) * parseFloat(this.exchangeRate)).toFixed(2);
      }
    });
    getSumInterest(this.currency, this.from, this.to, (data, error) => {
      if (error) {
        this.errored = true;
        this.error = error;
        console.log("comp errored!");
      }
      if (data) {
        // console.log(data);
        this.earnedInterest = data.sum;
        // console.log(this.earnedInterest + " 2 " + this.exchangeRate);
        this.earnedInterestInEuro = (parseFloat(this.earnedInterest) * parseFloat(this.exchangeRate)).toFixed(2);
      }
    });
    getInitialData((data, error) => {
      if (error) {
        this.errored = true;
        this.error = error;
        console.log("comp errored!");
      }
      if (data) {
        // console.log(data);
        this.daysSinceInvestment = this.convertUnix(data.first_time);
        // console.log(this.exchangeRate);
      }
    });
    getAvgInterestRate(this.currency, this.from, this.to, (data, error) => {
      if (error) {
        this.errored = true;
        this.error = error;
        console.log("comp errored!");
      }
      if (data) {
        // console.log(data);
        this.averageInterestRate = data.avg_interest_rate;
        // console.log(this.exchangeRate);
      }
    });
  },
  mounted() {
    // this.balanceInEuro = (parseFloat(this.balance) * parseFloat(this.exchangeRate)).toFixed(3);
    // this.balanceInEuro = this.balance;
    // console.log(this.earnedInterest);
    // this.earnedInterestInEuro = (parseFloat(this.earnedInterest.sum) * parseFloat(this.exchangeRate)).toFixed(2);
  },
  methods: {
    convertUnix(unixTimestamp) {
      // return moment(unixTimestamp).format("DD.MM.YYYY - HH:mm:ss");
      let startDate = moment(unixTimestamp).format("YYYY-MM-DD");
      let endDate = moment().format("YYYY-MM-DD");
      return moment(endDate).diff(moment(startDate), "days") + 1;
    },
    calc_avg_interest(data) {
      const sum = data.reduce((acc, curr) => {
        // Wenn last_lent_amount nicht null ist, füge das Ergebnis der Division hinzu
        if (curr.last_lent_amount !== null && curr.interest !== null) {
          acc += (curr.interest * 365 * 24 * 100) / curr.last_lent_amount;
        }
        return acc;
      }, 0);
      // const average = sum / this.data.length;
      return (sum / this.data.length).toFixed(2);
    },
    onFlip() {
      console.log("flipped!");
    },
    onManualFlip() {
      this.$refs.flipcard.flip();
      // this.cardHeight = this.flipped ? "300px" : "500px";
      this.flipped = !this.flipped;
      if (this.$refs.flipcard.back) {
        this.$refs.flipcard._props.height = this.$refs.card_back.clientHeight;
        this.$refs.card_front.parentNode.style.zIndex = "auto";
      } else {
        this.$refs.flipcard._props.height = this.$refs.card_front.clientHeight;
        this.$refs.card_front.parentNode.style.zIndex = "2";
      }
    },
  },
};
</script>

<style scoped>
.info-card-container {
  padding: 5px 10px 5px 10px;
}

.info-card {
  /*background: linear-gradient(45deg, #64d4d7, #3b6df6);*/
  background-color: #141319;
  box-shadow: 0 0 15px rgba(73, 73, 73, 0.3);
  color: white;
}

.info-card .row {
  text-align: left;
}

.info-column {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}

.info-description {
  font-weight: bold;
  font-size: 10px;
}

.info-value {
  margin-top: 0px; /* Abstand zwischen Beschreibung und Wert */
}

.mini-text {
  color: #8d8d8d;
  font-size: 10px;
}
</style>
