<template>
  <div id="app">
    <!-- Hier können Sie andere Anfragen platzieren -->
    <priceBarComponent v-if="data" :data="data" />
    <infoBarComponent v-if="data && exchangeRate" :data="data" :exchangeRate="exchangeRate" />
    <h2>Heute</h2>
    <interestCardComponent v-if="todaysData && title" :title="title" :data="todaysData" />
    <b-button @click="click">Hallo</b-button>
    <p></p>
    <line-chart-component />
  </div>
</template>

<script>
import { fetchSimpleEarnData, getExchangeRate } from "./client/index";
import interestCardComponent from "./components/interestCardComponent.vue";
import lineChartComponent from "./components/lineChartComponent.vue";
import infoBarComponent from "./components/infoBarComponent.vue";
import priceBarComponent from "./components/priceBarComponent.vue";
import moment from "moment";
require("moment/locale/de");
// Moment auf Deutsch einstellen
moment.locale("de");
// import VueFlipcard from "vue-flipcard";

export default {
  name: "GateIO",
  components: {
    interestCardComponent,
    lineChartComponent,
    infoBarComponent,
    priceBarComponent,
  },
  data() {
    return {
      error: null,
      errored: false,
      data: null,
      from: "",
      to: "",
      currency: "",
      title: "hallo",
      todaysData: null,
      exchangeRate: null,
    };
  },
  created() {
    fetchSimpleEarnData(this.from, this.to, this.currency, (data, error) => {
      if (error) {
        this.errored = true;
        this.error = error;
        console.log("comp errored!");
      }
      if (data) {
        this.data = data;
        // console.log(this.data);
        this.getTodaysData();
      }
    });
    getExchangeRate("USDTEUR", (data, error) => {
      if (error) {
        this.errored = true;
        this.error = error;
        console.log("comp1 errored!");
      }
      if (data) {
        // console.log(data);
        this.exchangeRate = parseFloat(data.exchange_rate).toFixed(4);
        // console.log(this.exchangeRate);
        // console.log(this.exchangeRate + " 1 " + this.earnedInterest.sum);
      }
    });
  },
  mounted() {},
  methods: {
    click() {
      //
    },
    getTodaysData() {
      this.todaysData = this.data
        .filter(({ create_time }) => {
          const currentTime = moment(create_time);
          return currentTime.isSame(moment(), "day") && currentTime.isSameOrAfter(moment().startOf("day").hour(1));
        })
        .sort((a, b) => {
          return moment(b.create_time).valueOf() - moment(a.create_time).valueOf();
        });
      // console.log(this.todaysData);
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
