import axios from "axios";

const protocol = "https://";
const host = "gateio.dn-deliveries.de/api/";

// const storage = window.sessionStorage;

function fetchSimpleEarnData(from = "", to = "", currency = "", callback) {
  const endpoint = "get_simple_earn.php";
  const url = protocol + host + endpoint;
  axios
    .get(url, {
      params: {
        from: from,
        to: to,
        currency: currency,
      },
    })
    .then((response) => {
      // handle success
      // console.log(response);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

function getInitialData(callback) {
  const endpoint = "get_initial_data.php";
  const url = protocol + host + endpoint;
  axios
    .get(url, {})
    .then((response) => {
      // handle success
      // console.log(response);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

async function getExchangeRate(pair = "USDTEUR", callback) {
  const endpoint = "get_exchange_rate.php";
  const url = protocol + host + endpoint;
  await axios
    .get(url, {
      params: {
        pair: pair,
      },
    })
    .then((response) => {
      // handle success
      // console.log(response.data);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

function getLends(callback) {
  const endpoint = "get_lends.php";
  const url = protocol + host + endpoint;
  axios
    .get(url, {})
    .then((response) => {
      // handle success
      // console.log(response.data);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

// unused
function getSpotAssets(callback) {
  const endpoint = "get_spot_assets.php";
  const url = protocol + host + endpoint;
  axios
    .get(url, {})
    .then((response) => {
      // handle success
      // console.log(response.data);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

function getSumInterest(currency = "", from = "", to = "", callback) {
  const endpoint = "get_sum_interest.php";
  const url = protocol + host + endpoint;
  axios
    .get(url, {
      params: {
        currency: currency,
        from: from,
        to: to,
      },
    })
    .then((response) => {
      // handle success
      // console.log(response.data);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

function getAvgInterestRate(currency = "", from = "", to = "", callback) {
  const endpoint = "get_avg_interest_rate.php";
  const url = protocol + host + endpoint;
  axios
    .get(url, {
      params: {
        currency: currency,
        from: from,
        to: to,
      },
    })
    .then((response) => {
      // handle success
      // console.log(response.data);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

function getAwardedGT(callback) {
  const endpoint = "get_awarded_gt.php";
  const url = protocol + host + endpoint;
  axios
    .get(url, {})
    .then((response) => {
      // handle success
      // console.log(response.data);
      callback(response.data, null);
    })
    .catch((error) => {
      // handle error
      console.log(error);
      callback(null, error);
    });
}

export { fetchSimpleEarnData, getInitialData, getExchangeRate, getLends, getSpotAssets, getSumInterest, getAvgInterestRate, getAwardedGT };
