<template>
  <LineChartGenerator
    v-if="data1"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement);

export default {
  name: "lineChartComponent",
  components: {
    LineChartGenerator,
  },
  props: {
    data1: Array,
    // data2: Array,
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "0"],
        datasets: [
          {
            label: "APR",
            backgroundColor: "#f87979",
            yAxisID: "leftAxis",
            data: null,
          },
          {
            label: "Zinsen",
            // backgroundColor: "#f87979",
            yAxisID: "rightAxis",
            data: null,
            backgroundColor: "rgba(255, 0, 0, 1)",
            pointRadius: 0,
            pointHitRadius: 0,
            // daten werden unten uberschrieben
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            position: "nearest",
            callbacks: {
              title: function (tooltipItems) {
                // Get the original title
                let title = tooltipItems[0].label || "";
                // Add your suffix here
                const suffix = ":00 Uhr";
                // Concatenate the original title with the suffix
                title += suffix;
                return title;
              },
            },
          },
        },
        stacked: false,
        pointRadius: 0,
        pointHoverRadius: 10,
        pointHitRadius: 20,
        pointBorderWidth: 1,
        pointBackgroundColor: "#60bc8b",
        borderWidth: 5,
        borderColor: "#60bc8b",
        interaction: {
          mode: "index",
          intersect: false,
        },
        scales: {
          rightAxis: {
            showLine: false,
            display: false,
            id: "rightAxis",
            type: "linear",
            position: "right",
            ticks: {
              color: "white",
              font: {
                size: 14,
              },
            },
          },
          leftAxis: {
            id: "leftAxis",
            type: "linear",
            position: "left",
            ticks: {
              callback: function (val, index) {
                return (index + 1) % 1 === 0 ? this.getLabelForValue(val) : "";
              },
              color: "white",
              font: {
                size: 14,
              },
            },
            grid: {
              color: "white",
            },
          },
          x: {
            ticks: {
              callback: function (val, index) {
                return (index + 1) % 1 === 0 ? this.getLabelForValue(val) : "";
              },
              color: "white",
              font: {
                size: 14,
              },
            },
            grid: {
              color: "white",
            },
          },
        },
      },
    };
  },
  watch: {
    data1: {
      handler() {
        if (this.data1) {
          this.chartData.datasets = [
            {
              label: "APR",
              backgroundColor: "#f87979",
              yAxisID: "leftAxis",
              data: this.calc_data1(this.data1).reverse(),
            },
            {
              label: "Zinsen",
              // backgroundColor: "#f87979",
              yAxisID: "rightAxis",
              data: this.calc_data2(this.data1).reverse(),
              backgroundColor: "rgba(0, 0, 0, 0)",
              pointRadius: 0,
              pointHitRadius: 0,
              pointHoverRadius: 0,
              showLine: false,
            },
          ];
        }
      },
      immediate: true,
    },
  },
  methods: {
    calc_data1(data) {
      return data.map((item) => ((item.interest * 365 * 24 * 100) / item.last_lent_amount).toFixed(2));
    },
    calc_data2(data) {
      return data.map((item) => item.interest.toFixed(3));
    },
  },
};
</script>
