<template>
  <div v-if="menuItems">
    <div class="card-container">
      <vue-flipcard ref="flipcard" disable>
        <div class="content front" ref="card_front" slot="front" @click="onManualFlip">
          <h2>{{ titel }}</h2>
        </div>
        <div class="content back" ref="card_back" slot="back" @dblclick="onManualFlip">
          <h1>背面 | Back{{ titel }}</h1>
          <div class="hourly_desc">
            <p>Darlehensbetrag/Effektiver Jahreszins</p>
            <p>Zinsen</p>
          </div>
          <!-- @touchmove.stop.prevent -->
          <b-list-group class="list-group-shadow" style="max-height: 300px; overflow-y: scroll; overflow-x: hidden">
            <b-list-group-item v-for="(item, index) in data" :key="index">
              <div class="row">
                <!-- Zwei Werte links -->
                <div class="col-md-6 col-sm-6 col-6">
                  <p>{{ item.last_lent_amount }}</p>
                  <p>{{ ((item.interest * 365 * 24 * 100) / item.last_lent_amount).toFixed(2) }}%</p>
                </div>
                <!-- Zwei Werte rechts -->
                <div class="col-md-6 col-sm-6 col-6 text-md-right text-sm-right text-right">
                  <p class="text-md-right">{{ item.interest }}</p>
                  <!-- Berechnung der zusätzlichen Variable -->
                  <p class="text-md-right">{{ convertUnix(item.create_time) }}</p>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-container fluid class="px-0" style="padding-top: 10px">
            <b-row no-gutters>
              <b-col cols="8">
                <div class="left" @touchmove.stop.prevent>
                  <line-chart-component :data1="data" style="height: 20vh; width: 100%" />
                </div>
              </b-col>
              <b-col cols="4" class="d-flex justify-content-center align-items-center">
                <div class="right">
                  <div class="content text-center average-decoration">
                    <span>{{ averageSymbol }}</span>
                    <span class="d-block mb-2">{{ calc_avg_interest(data) }}%</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <p>DAS IST ZEILE VIER DAS IST ZEILE VIER</p>
        </div>
      </vue-flipcard>
    </div>
  </div>
</template>

<script>
import VueFlipcard from "vue-flipcard";
import lineChartComponent from "../components/lineChartComponent.vue";
import moment from "moment";
require("moment/locale/de");
// Moment auf Deutsch einstellen
moment.locale("de");

export default {
  name: "interestCardComponent",
  components: {
    VueFlipcard,
    lineChartComponent,
  },
  props: {
    title: String,
    data: Array,
  },
  data() {
    return {
      number: 42,
      averageSymbol: "Ø",
      averageNumber: 25,
      menuItems: this.data, // Beispiel-Liste
      // menuItems2: ["Item 1", "Item 2", "Item 3", "Item 1", "Item 2", "Item 3"], // Beispiel-Liste
      flipped: false,
      titel: this.title,
    };
  },
  mounted() {
    // this.$refs.flipcard._props.height = this.$refs.card_front.clientHeight;
    if (this.$refs.flipcard.back) {
      this.$refs.flipcard._props.height = this.$refs.card_back.clientHeight;
      this.$refs.card_front.parentNode.style.zIndex = "auto";
    } else {
      this.$refs.flipcard._props.height = this.$refs.card_front.clientHeight;
      this.$refs.card_front.parentNode.style.zIndex = "2";
    }
    console.log(this.data);
  },
  methods: {
    convertUnix(unixTimestamp) {
      return moment(unixTimestamp).format("DD.MM.YYYY - HH:mm:ss");
    },
    calc_avg_interest(data) {
      const sum = data.reduce((acc, curr) => {
        // Wenn last_lent_amount nicht null ist, füge das Ergebnis der Division hinzu
        if (curr.last_lent_amount !== null && curr.interest !== null) {
          acc += (curr.interest * 365 * 24 * 100) / curr.last_lent_amount;
        }
        return acc;
      }, 0);
      // const average = sum / this.data.length;
      return (sum / this.data.length).toFixed(2);
    },
    onFlip() {
      console.log("flipped!");
    },
    onManualFlip() {
      this.$refs.flipcard.flip();
      // this.cardHeight = this.flipped ? "300px" : "500px";
      this.flipped = !this.flipped;
      if (this.$refs.flipcard.back) {
        this.$refs.flipcard._props.height = this.$refs.card_back.clientHeight;
        this.$refs.card_front.parentNode.style.zIndex = "auto";
      } else {
        this.$refs.flipcard._props.height = this.$refs.card_front.clientHeight;
        this.$refs.card_front.parentNode.style.zIndex = "2";
      }
    },
  },
};
</script>

<style scoped>
.card-container {
  text-align: center;
  /*padding: 10px;*/
  padding: 5px 10px 5px 10px;
}

.vue-flipcard {
  display: inline-block;
  /*height: unset !important;*/
  width: 100% !important;
}
.content {
  padding: 15px 10px;
  text-align: left;
  background-color: #141319;
  color: white;
}
.vue-flipcard__front,
.vue-flipcard__back {
  height: unset !important;
  width: 100% !important;
  z-index: unset !important;
}

.hourly_desc {
  color: #8d8d8d;
  font-size: 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}
.hourly_desc p:nth-child(1) {
  text-align: left;
  margin: 0;
  padding-bottom: 4px;
}
.hourly_desc p:nth-child(2) {
  text-align: right;
  margin: 0;
}

.list-group-shadow {
  background-color: #141319;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.list-group-shadow .list-group-item {
  background-color: unset;
}
.list-group {
  margin: 0px -10px;
}
.list-group p {
  margin-bottom: 0px;
}
.list-group .list-group-item {
  padding: 0.4rem 0.5rem;
}
.list-group .row div:nth-child(1) p:nth-child(1) {
  color: white;
}
.list-group .row div:nth-child(2) p:nth-child(1) {
  color: white;
}
.list-group .row div:nth-child(1) p:nth-child(2) {
  color: #60bc8b;
  font-size: 10px;
}
.list-group .row div:nth-child(2) p:nth-child(2) {
  color: #8d8d8d;
  font-size: 10px;
}

.average-decoration {
  /*background: linear-gradient(45deg, #4caf50, #2196f3);*/
  background: linear-gradient(45deg, #64d4d7, #3b6df6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 26px;
  font-weight: bold;
}
</style>
